// Generated by Framer (64125eb)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["wYx3CnP36"];

const variantClassNames = {wYx3CnP36: "framer-v-1525ibw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, title, width, ...props}) => { return {...props, MKY92Co_B: title ?? props.MKY92Co_B ?? "Assistenza tecnica", ZpHxOJjA4: image ?? props.ZpHxOJjA4 ?? {src: new URL("assets/jN7yUI1JS2v6w9MGLyUoANoscQ.svg", import.meta.url).href}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, MKY92Co_B, ZpHxOJjA4, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "wYx3CnP36", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-lvkSO", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1525ibw", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"wYx3CnP36"} ref={ref} style={{...style}}><Image background={{alt: "", fit: "fit", intrinsicHeight: 36, intrinsicWidth: 39, pixelHeight: 36, pixelWidth: 39, sizes: "36px", ...toResponsiveImage(ZpHxOJjA4)}} className={"framer-140pf97"} layoutDependency={layoutDependency} layoutId={"hh5c47_uQ"}/><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItTWVkaXVt", "--framer-font-family": "\"Inter-Medium\", \"Inter\", sans-serif", "--framer-font-size": "18px", "--framer-font-weight": "500", "--framer-letter-spacing": "0px", "--framer-line-height": "30px", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Assistenza tecnica</motion.p></React.Fragment>} className={"framer-1wt6vwk"} fonts={["Inter-Medium"]} layoutDependency={layoutDependency} layoutId={"CJdwTW5yL"} style={{"--extracted-r6o4lv": "var(--token-63b4f2c3-6eca-4822-9037-55f1154c9e35, rgb(38, 38, 65))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={MKY92Co_B} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-lvkSO [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-lvkSO .framer-1q8bauw { display: block; }", ".framer-lvkSO .framer-1525ibw { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 52px; justify-content: flex-start; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-lvkSO .framer-140pf97 { align-content: flex-start; align-items: flex-start; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 39px; justify-content: flex-start; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 36px; }", ".framer-lvkSO .framer-1wt6vwk { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-lvkSO .framer-1525ibw, .framer-lvkSO .framer-140pf97 { gap: 0px; } .framer-lvkSO .framer-1525ibw > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-lvkSO .framer-1525ibw > :first-child { margin-left: 0px; } .framer-lvkSO .framer-1525ibw > :last-child { margin-right: 0px; } .framer-lvkSO .framer-140pf97 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-lvkSO .framer-140pf97 > :first-child { margin-top: 0px; } .framer-lvkSO .framer-140pf97 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 52
 * @framerIntrinsicWidth 208
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]}}}
 * @framerVariables {"MKY92Co_B":"title","ZpHxOJjA4":"image"}
 */
const FramermrKi7zUdN: React.ComponentType<Props> = withCSS(Component, css, "framer-lvkSO") as typeof Component;
export default FramermrKi7zUdN;

FramermrKi7zUdN.displayName = "store-service";

FramermrKi7zUdN.defaultProps = {height: 52, width: 208};

addPropertyControls(FramermrKi7zUdN, {MKY92Co_B: {defaultValue: "Assistenza tecnica", displayTextArea: false, title: "Title", type: ControlType.String}, ZpHxOJjA4: {__defaultAssetReference: "data:framer/asset-reference,jN7yUI1JS2v6w9MGLyUoANoscQ.svg?originalFilename=assistenza-store.svg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramermrKi7zUdN, [])